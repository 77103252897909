import React, { ReactNode } from 'react';
import moment from 'moment';
import { Badge } from 'antd';

import { ReactComponent as CreatedSvg } from '@assets/icons/task-activity-created.svg';
import { ReactComponent as DueDateSvg } from '@assets/icons/task-activity-due-date.svg';
import { ReactComponent as DueDateUpdateSvg } from '@assets/icons/task-activity-due-date-update.svg';
import { ReactComponent as WriteSvg } from '@assets/icons/task-activity-write.svg';
import { ReactComponent as TaskGroupSvg } from '@assets/icons/task-activity-set-group.svg';
import { ReactComponent as PrioritySvg } from '@assets/icons/task-activity-set-priority.svg';
import { ReactComponent as WriteGreenSvg } from '@assets/icons/task-activity-set-description.svg';
import { ReactComponent as UploadFileSvg } from '@assets/icons/task-activity-upload-file.svg';
import { ReactComponent as LinkAssetSvg } from '@assets/icons/task-activity-link-asset.svg';
import { ReactComponent as DeleteSvg } from '@assets/icons/task-activity-delete-icon.svg';
import './Activity.less';
import UserAvatar from '@components/UserAvatar';
import { TaskEventDto } from '@api/Api';
import classNames from 'classnames';

interface IActivityProps {
  isLastActivity: boolean;
  activity: TaskEventDto;
}

function Activity({ activity, isLastActivity }: IActivityProps) {
  interface ICommentActivityItemProps {
    user: any;
    eventTime: Date;
    newActivityText: string;
    activityIcon: ReactNode | null;
  }

  interface IDefaultActivityItemProps {
    user: any;
    eventTime: Date;
    actionText: string;
    prevActivityText: string;
    intermediateText: string;
    newActivityText: string;
    activityIcon: ReactNode | null;
    isRichText?: boolean;
  }

  function defaultActivityItem({
    user,
    eventTime,
    actionText,
    prevActivityText,
    intermediateText,
    newActivityText,
    activityIcon = null,
    isRichText = false
  }: IDefaultActivityItemProps) {
    return (
      <>
        <div className="activity-container">
          <div className={classNames(!isLastActivity && 'activity-avatar')}>
            <Badge count={activityIcon} offset={[-7, 31]}>
              <UserAvatar
                isActive={true}
                size="large"
                src={user?.picture?.url || ''}
                userEmail={user?.email || ''}
                userName={user?.name ?? 'Deleted User'}
              />
            </Badge>
          </div>
          <span className="activity-name">
            <span style={{ fontWeight: 400 }}>
              {user?.me ? 'You' : user?.name ?? 'Deleted User'} {actionText}{' '}
            </span>
            {!isRichText && (
              <span style={{ fontWeight: 700 }}>{prevActivityText}</span>
            )}
            {isRichText && (
              <div
                className="default-rich-text"
                dangerouslySetInnerHTML={{ __html: prevActivityText }}
              />
            )}
            {prevActivityText && (
              <span style={{ fontWeight: 400 }}>{intermediateText}</span>
            )}
            {!isRichText && (
              <span style={{ fontWeight: 700 }}>{newActivityText}</span>
            )}
            {isRichText && (
              <div
                className="default-rich-text"
                dangerouslySetInnerHTML={{ __html: newActivityText }}
              />
            )}
          </span>
          <span className="activity-time">
            {moment(eventTime).format('MMM DD, h:mm a')}
          </span>
        </div>
        {/* {!isLastActivity && <div className="activity-line" />} */}
      </>
    );
  }

  defaultActivityItem.defaultProps = {
    user: null,
    eventTime: '',
    actionText: '',
    prevActivityText: '',
    intermediateText: '',
    newActivityText: '',
    activityIcon: null
  };

  function commentActivityItem({
    user,
    eventTime,
    newActivityText,
    activityIcon = null
  }: ICommentActivityItemProps) {
    return (
      <>
        <div className="comment-activity-container">
          {defaultActivityItem({
            user,
            eventTime,
            actionText: '',
            prevActivityText: 'left a new comment to this task',
            intermediateText: '',
            newActivityText: '',
            activityIcon
          })}
          <div
            className="comment-text"
            dangerouslySetInnerHTML={{ __html: newActivityText }}
          />
          <div className="activity-line comment-activity-line" />
        </div>
        {!isLastActivity && <div className="activity-line" />}
      </>
    );
  }

  commentActivityItem.defaultProps = {
    user: null,
    eventTime: '',
    newActivityText: '',
    activityIcon: null
  };

  const changeDateFormat = (date: any) =>
    moment(date).minutes() === 59
      ? moment(date).format('MMM DD')
      : moment(date).format('MMM DD, h:mm a');

  const activitySelector = (activity: any) => {
    const { user, eventTime, payload } = activity;

    switch (activity.payload.type) {
      case 'created':
        return defaultActivityItem({
          user,
          eventTime,
          actionText: 'created task ',
          prevActivityText: '',
          intermediateText: '',
          newActivityText: payload.name,
          activityIcon: <CreatedSvg />
        });
      case 'status_changed': {
        const prevStatusText =
          payload.prevStatus === 'in_progress'
            ? 'In progress'
            : payload.prevStatus === 'done'
            ? 'Done'
            : payload.prevStatus === 'todo'
            ? 'To do'
            : payload.prevStatus === 'stuck'
            ? 'Blocked'
            : payload.prevStatus;

        const newStatusText =
          payload.newStatus === 'in_progress'
            ? 'In progress'
            : payload.newStatus === 'done'
            ? 'Done'
            : payload.newStatus === 'todo'
            ? 'To do'
            : payload.newStatus === 'stuck'
            ? 'Blocked'
            : payload.newStatus;

        return defaultActivityItem({
          user,
          eventTime,
          actionText: payload.prevStatus
            ? 'updated task status from '
            : 'set status ',
          prevActivityText: payload.prevStatus ? prevStatusText : '',
          intermediateText: ' to ',
          newActivityText: newStatusText,
          activityIcon: <WriteSvg />
        });
      }
      case 'priority_changed':
        return defaultActivityItem({
          user,
          eventTime,
          actionText:
            payload.newPriority !== null ? 'set priority ' : 'delete priority',
          prevActivityText: '',
          intermediateText: '',
          newActivityText: payload.newPriority
            ? payload.newPriority.charAt(0).toUpperCase() +
              payload.newPriority.slice(1)
            : '',
          activityIcon: payload.newPriority ? <PrioritySvg /> : <DeleteSvg />
        });
      case 'description_changed':
        return defaultActivityItem({
          user,
          eventTime,
          actionText: payload.prevDescription
            ? 'update task description '
            : 'set task description to ',
          prevActivityText: payload.prevDescription
            ? payload.prevDescription
            : '',
          intermediateText: ' to ',
          newActivityText: payload.newDescription,
          activityIcon: payload.prevDescription ? (
            <WriteSvg />
          ) : (
            <WriteGreenSvg />
          ),
          isRichText: true
        });
      case 'comment':
        return commentActivityItem({
          user,
          eventTime,
          newActivityText: payload.text,
          activityIcon: <DueDateSvg />
        });
      case 'renamed':
        return defaultActivityItem({
          user,
          eventTime,
          actionText: 'renamed task ',
          prevActivityText: payload.prevName,
          intermediateText: ' to ',
          newActivityText: payload.newName,
          activityIcon: <WriteSvg />
        });
      case 'due_date_changed':
        return defaultActivityItem({
          user,
          eventTime,
          actionText:
            payload.prevDueDate && payload.newDueDate
              ? 'updated due date from '
              : payload.newDueDate
              ? 'set due date '
              : 'unset due date ',
          prevActivityText: payload.prevDueDate
            ? changeDateFormat(payload.prevDueDate)
            : '',
          intermediateText: payload.newDueDate ? ' to ' : '',
          newActivityText: payload.newDueDate
            ? changeDateFormat(payload.newDueDate)
            : '',
          activityIcon: payload.prevDueDate ? (
            <DueDateUpdateSvg />
          ) : (
            <DueDateSvg />
          )
        });
      case 'board_changed':
        return defaultActivityItem({
          user,
          eventTime,
          actionText: payload.prevBoard
            ? 'updated task group name from '
            : 'set task group name to ',
          prevActivityText: payload.prevBoard
            ? payload.prevBoard.id === 'no_assigned'
              ? 'No task group assigned'
              : payload.prevBoard.name
            : '',
          intermediateText: ' to ',
          newActivityText:
            payload.newBoard.id === 'no_assigned'
              ? 'No task group assigned'
              : payload.newBoard.name,
          activityIcon: payload.prevBoard ? <WriteSvg /> : <TaskGroupSvg />
        });
      case 'attachment_created':
        return defaultActivityItem({
          user,
          eventTime,
          actionText: 'attached file ',
          newActivityText: payload.attachment.name,
          prevActivityText: '',
          intermediateText: '',
          activityIcon: <UploadFileSvg />
        });
      case 'attachment_deleted':
        return defaultActivityItem({
          user,
          eventTime,
          actionText: 'deleted file ',
          newActivityText: payload.attachment.name,
          prevActivityText: '',
          intermediateText: '',
          activityIcon: <DeleteSvg />
        });
      case 'asset_version_changed':
        return defaultActivityItem({
          user,
          eventTime,
          actionText:
            payload.newAssetVersion !== null
              ? 'linked media '
              : 'deleted media ',
          newActivityText:
            payload.newAssetVersion !== null
              ? `${payload.newAssetVersion.name}.${payload.newAssetVersion.extension}`
              : `${payload.prevAssetVersion.name}.${payload.prevAssetVersion.extension}`,
          prevActivityText: '',
          intermediateText: '',
          activityIcon:
            payload.newAssetVersion !== null ? <LinkAssetSvg /> : <DeleteSvg />
        });
      case 'deleted':
        return defaultActivityItem({
          user,
          eventTime,
          actionText: 'deleted task',
          newActivityText: payload.name,
          prevActivityText: '',
          intermediateText: '',
          activityIcon: <DeleteSvg />
        });
      default:
        return null;
    }
  };

  return <div>{activitySelector(activity)}</div>;
}

export default Activity;
